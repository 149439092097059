* {
  box-sizing: border-box;
  margin: 0;
  /* font-weight: 600; */

  font-family: "Crisp Graphik Medium", sans-serif;
}
main {
  background-color: #f3f6fb;
}
Select {
  width: 400px;
  text-align-last: center;
}
.Search {
  border: none !important;

  background: rgba(255, 255, 255, 0) !important;
  height: 20 px;
  width: 100%;
  font-size: 15.75px;
  border: none;
  text-align: center !important;
  text-decoration: none;
  /* color: white; */
  border: none;
}

.ant-select-selector {
  border: none !important;
  height: 60px !important;
  width: 100%;
  font-size: 15.75px !important;
  border: none;
  text-align: center !important;
  text-decoration: none;
  color: white !important;
  border: none;
  text-align-last: center !important;
  background: rgba(255, 255, 255, 0.25) !important;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  border-radius: 10px;
  margin: auto !important;
}
.ant-select-arrow {
  display: none !important;
}
.ant-select-item-empty {
  text-align: center !important;
}
.ant-select-selection-search-input {
  height: 60px !important;
  font-size: 15.75px !important;

  text-align: center !important;
}
.ant-select-selection-item {
  margin-top: 15px !important;
  text-align: center !important;
  width: 100%;
  position: absolute !important;
}
.ant-empty-image {
  display: none !important;
}
.searchicon {
  margin-top: 35px;
  margin-left: 22px;
  color: #fff;
  float: left;
  font-size: 20px;
  position: absolute;
  z-index: 20;
}
.search_bar {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  /* height: 50px; */
  text-align: center;
  color: #fff;
  border-radius: 10px;
}

.container_search {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.Search::placeholder {
  color: white;
  opacity: 1;
  text-align: center;
}
.header {
  top: 0;
  height: fit-content !important;
  background-color: #bd025d !important;
}
.title {
  color: #fff;
  font-size: 17.55px;
  font-weight: 700;
  letter-spacing: 0 !important;
  cursor: pointer;
}
.title2 {
  color: #fff;
  font-size: 23.4px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 0 !important;

  text-align: center;
  letter-spacing: 0.1px;
  /* margin-bottom: 24px; */
}
.subtitleee {
  color: #37516c;
  font-size: 13.5px;
  line-height: 20px;
  padding-top: 4px;
}
.weblink {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 13.5px;
  padding: 10px 30px;
  line-height: 37px;
  letter-spacing: 0.25px;
  vertical-align: middle;
  height: 38px;
  border-radius: 6px;
}
.chatbtn:hover {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  font-size: 13.05px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  padding: 0px 25px;
  height: 44px;
  align-items: center;
  justify-content: space-between;

  background-color: #bd025d;
  /* margin: 0 7px; */
}
.chatbtn {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13.05px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  height: 44px;
  align-items: center;
  background-color: #bd025d;
  padding: 0px 25px;

  display: inline-flex;
}
.Categories_btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  height: 32px;
  max-width: 225px;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0 13px;
  background-color: #bd025d;
  font-size: 14.4px;
  letter-spacing: .25px;
  line-height: 31px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 14px;
}
.Categories_btn:hover {
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
 
}
.mr-10 {
  margin: 0 auto;
  justify-content: center;
  /* margin: 300px; */
}
h1 {
  color: black;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: bolder;
}
.line_css {
  color: #37516c;
  font-size: 14.85px;
  letter-spacing: 0.1px;
  margin-top: 14px;
}
.footer {
  margin-top: 70px;
}
.button {
  display: flex;
  justify-content: center;
}
.btn {
  margin-right: 10px;
  margin-left: 5px;
  font-size: large;
}
.text_footer {
  /* text-align: center; */
}
.text_row {
  justify-content: center !important;
  max-width: 980px;
  margin: auto;
}
.ant-layout-footer {
  background-color: white !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  /* color: #bd025d!important; */
  display: none !important;
} 
