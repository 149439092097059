/* .ant-menu-item-selected {
    background-color: rgba(67, 172, 106, .2);
    color: black;
    font-weight: 700;
} */

.ant-menu-item:hover {
  background-color: #dfe5eb;
  color: white;
}

.menu-item.ant-menu-item-selected:where {
  background-color: #bd025d;
}

.menu-item.ant-menu-item-selected {
  background-color: #dfe5eb;
  color: black;
  font-weight: 700;
}
.menu-item {
  height: 35px !important;
}
.backbtn{
    cursor: pointer;
    margin-top: 5px;

}
