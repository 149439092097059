.guide_with_img img {
  max-width: 100%;
}
.guide_with_img p {
  /* margin-top: 50px; */
  margin-bottom: 10px;
}
.helpful {
  color: #37516c;
  font-size: 14.4px;
  /* margin-right: 13px; */
  font-weight: 600;
  letter-spacing: 1px;
}
.yesorno {
  color: #1c293b;
  background-color: #dfe5eb;
  margin: 3px;
  line-height: 33px;
  height: 36px;
  padding: 0 18px;
  border-radius: 8px;
  font-weight: 700 !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}
.yesorno:hover {
  color: #1c293b!important;
  background-color: #dfe5eb;
  margin: 3px;
  line-height: 33px;
  height: 36px;
  padding: 0 18px;
  border-radius: 8px;
  font-weight: 700 !important;
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%)!important;
}
.updateDate {
  color: #37516c;
  font-size: 13.5px;
  line-height: 20px;
  text-align: center;
  margin-top: 22px;
}
.modal_span {
  display: flex;
  color: #1c293b;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 800;
}

.modal_span img {
  margin-right: 15px;
}

.text_place {
  margin-bottom: 15px;
}

.text_place::placeholder {
  color: #1c293b;
}

.send_btn {
  background-color: #bd025d;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 44px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  color: white;
  width: 59%;
}

.send_btn:hover {
  color: white !important;
  border-color: white !important;
}

.close_btn {
  background-color: #f7f9fc;
  color: #1c293b !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 44px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  color: white;
  width: 38%;
}
.close_btn:hover {
  color: #1c293b !important;
}
 