.card_img img {
  width: 100px;
  height: 100px;
  margin-right: 38px;
}
Card {
  padding: 12px 24px;
}

.card_col {
  margin-bottom: 16px;
}
.card_wrapper {
  display: flex;
}
Button:hover {
  color: white !important;
  border: white !important;
}
.row_dis {
  width: 100%;
  margin: 0% !important;
}
/* .ant_row {
  width: 100%;
  margin: 5px!important;
} */
.ant-layout {
  background-color: #f3f6fb;
}
.ant-layout-header {
  padding-bottom: 10px;
}
.question_title {
  font-size: 21.6px;
  font-weight: 900;
  margin-bottom: 20px;
}

.ant-card {
  cursor: pointer;
}
.ant-card-body .card_question {
  padding: 10px !important;
}